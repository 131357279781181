import React, { useState, useEffect } from "react";
import axiosInstance from "../config/axiosInstance";
import { Input, Textarea, Select, Option } from "@material-tailwind/react";

function BookingForm({
  bookingForm,
  errors,
  bookingDetails,
  handleChange,
  handleSelectChange,
}) {
  const [occasions, setOccasions] = useState([]);

  const getOccasion = () => {
    axiosInstance
      .get("data/get-occasion-dd")
      .then((response) => {
        let data = response.data;
        if (data.success == 1) {
          setOccasions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  useEffect(() => {
    getOccasion();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-[#1a1a1a] text-[25px] leading-[28px] tracking-[3px]">
        Booking Details
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <Input
          label="Booking Name"
          name="bookingName"
          value={bookingForm.bookingName}
          error={errors.bookingName}
          success={!errors.bookingName && bookingForm.bookingName !== ""}
          onChange={handleChange}
        />
        <Input
          label="People"
          name="noOfPeople"
          type="number"
          min={bookingDetails?.people || 0}
          max={bookingDetails?.maxpeopleallowed || 0}
          value={bookingForm.noOfPeople}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <Input
          label="WhatsApp Number"
          name="whatsAppNumber"
          value={bookingForm.whatsAppNumber}
          error={errors.whatsAppNumber}
          success={!errors.whatsAppNumber && bookingForm.whatsAppNumber !== ""}
          onChange={handleChange}
        />
        <Input
          label="Email"
          name="email"
          value={bookingForm.email}
          error={errors.email}
          success={!errors.email && bookingForm.email !== ""}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <Select
          label="Occasion"
          name="occasion"
          // value={bookingForm.occasion}
          onChange={(event) => handleSelectChange(event)}
        >
          {occasions.map((occasion) => (
            <Option key={occasion.id} value={String(occasion.id)}>
              {occasion.description}
            </Option>
          ))}
        </Select>
        <Input
          label="Special Person Name"
          name="specialPersonName"
          value={bookingForm.specialPersonName}
          onChange={handleChange}
        />
      </div>
      <Textarea
        label="Message"
        name="message"
        value={bookingForm.message}
        onChange={handleChange}
      />
    </div>
  );
}

export default BookingForm;
