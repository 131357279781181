import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";

function MainPage() {
  const [planDetails, setPlanDetails] = useState([]);

  const getAllPlans = () => {
    axiosInstance
      .get("customer/get-all-plans")
      .then((response) => {
        let data = response.data;
        if (data.success === 1) {
          setPlanDetails(data.data);
        } else {
          console.error("Unexpected response:", data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const gotoPage = (id) => {
    localStorage.setItem("storedRoomID", id);
  };

  useEffect(() => {
    getAllPlans();

    return () => {};
  }, []);

  return (
    <div className="layout flex flex-col p-5 lg:p-[40px_0_40px] gap-8">
      <Helmet>
        <title>Our Space</title>
        <meta
          name="description"
          content="This is the our space of our website."
        />
        <meta property="og:title" content="Our Space" />
        <meta
          property="og:description"
          content="This is the our space of our website."
        />
      </Helmet>
      {planDetails.map((plan, index) => {
        return (
          <div key={index} className="grid grid-cols-12 gap-[30px]">
            <div className="col-span-12 lg:col-span-6">
              <div className="ourSpaceImage">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}hor/${plan.image}`}
                  alt=""
                  className="rounded-[24px] w-full h-[300px] lg:h-[500px] object-cover"
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex items-center">
              <div className="content text-center lg:text-left flex flex-col gap-5">
                <div className="roomHeading">
                  <h4 className="text-[22px] sm:text-[24px] md:text-[24px] lg:text-[28px] xl:text-[30px] font-semibold">
                    {plan.title}
                  </h4>
                </div>
                <div className="roomDescription">
                  <div
                    dangerouslySetInnerHTML={{ __html: plan.description }}
                    className="text-[16px] sm:text-[18px] text-left font-normal  ml-5"
                  />
                </div>
                <NavLink
                  to={`/our-space/${plan.subid}`}
                  onClick={() => gotoPage(plan.id)}
                >
                  <div className="flex justify-start">
                    <button className="button hover:text-[#fff]">
                      <span>More</span>
                    </button>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MainPage;
