import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function BookingResponse() {
  const { referenceNumber } = useParams();

  return (
    <div className="">
      <Helmet>
        <title>Booking Response</title>
        <meta
          name="description"
          content="This is the booking response page of our website."
        />
        <meta property="og:title" content="Booking Response Page" />
        <meta
          property="og:description"
          content="This is the booking response page of our website."
        />
      </Helmet>
      {referenceNumber && (
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-4 md:col-span-6 sm:col-span-8 col-start lg:col-start-5 md:col-start-4 sm:col-start-3">
            <div className="flex flex-col gap-5 m-5 p-5 bg-blue-gray-50 rounded-lg shadow-lg">
              <div className="text-[18px] font-bold">Booking Summary</div>
              <div className="text-[18px]">
                Your booking has been received with booking reference number
              </div>
              <div className="text-[24px] font-bold text-[#4caf50]">
                {referenceNumber}
              </div>
              <div className="text-[18px]">
                Please make sure to note down your reference number, as you'll
                need it for any future correspondence with us.
              </div>
              <div className="text-[18px]">
                Our team will contact you shortly.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookingResponse;
