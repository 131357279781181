import React from "react";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <div className="p-4 md:p-[96px_144px]">
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="This is the about us page of our website."
        />
        <meta property="og:title" content="About Us Page" />
        <meta
          property="og:description"
          content="This is the about us page of our website."
        />
      </Helmet>
      <div className="text-[40px] font-semibold mb-4 md:mb-8">About Us</div>
      <div className="flex flex-col md:flex-row gap-8 md:gap-16">
        <div className="w-full md:w-[560px]">
          <h2 className="text-[20px]">
            At House Of Delights, we're passionate about turning life's moments into
            unforgettable celebrations. With a diverse range of celebration
            spaces, exceptional service, and affordable luxury starting from
            just ₹499, we're dedicated to making your milestones shine.
            <br /> <br /> As a customer-centric community of celebrators, we
            prioritize your happiness and are ready to create tailor-made,
            resounding successes for your special events. Join us in
            transforming your occasions into cherished memories. Contact us
            today to book your next unforgettable celebration at House Of Delights.
          </h2>
        </div>
        <div className="w-full md:w-[560px] md:h-[560px] rounded-[24px]">
          <img
            // src="https://storage.googleapis.com/tbt-pics/images/RT_2.jpeg"
            src={`${process.env.REACT_APP_IMAGE_URL}about.jpeg`}
            alt=""
            className="rounded-[24px]"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
