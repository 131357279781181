import React, { useEffect } from "react";
import axiosInstance from "../config/axiosInstance";

function CakeSelector({
  selectedCake,
  setSelectedCake,
  cakesData,
  setCakesData,
  bookingForm,
  selectedDecor,
  callCalculation,
}) {
  useEffect(() => {
    axiosInstance
      .get("data/get-cake-dd")
      .then((response) => {
        if (response.data.success === 1) {
          setCakesData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, []);

  const handleCakeSelection = (cake) => {
    const newSelectedCake = selectedCake?.id === cake.id ? null : cake;
    setSelectedCake(newSelectedCake);
    callCalculation(bookingForm.noOfPeople, newSelectedCake, selectedDecor);
  };

  const highlightSelected = (id) => {
    return selectedCake?.id === id ? "cakeCard gap-2 active" : "cakeCard gap-2";
  };

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-h-[190px] sm:max-h-[300px] w-full my-custom-scrollbar overflow-y-scroll">
        {cakesData.length > 0 &&
          cakesData.map((cake) => (
            <div
              key={cake.id}
              className={highlightSelected(cake.id)}
              onClick={() => handleCakeSelection(cake)}
            >
              <img
                src={cake.image}
                alt={cake.description}
                className="w-[100px] h-[100px] object-cover rounded"
              />
              <div className="flex flex-col">
                <span>{cake.description}</span>
                <span>Rs.{cake.amount}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default CakeSelector;
