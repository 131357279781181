import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axiosInstance from "../config/axiosInstance";
import BookingForm from "../components/BookingForm";
import BookingSummary from "../components/BookingSummary";
import Cakes from "../components/Cakes";
import SpecialDecor from "../components/SpecialDecor";
import { useLoading } from "../config/LoadingContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BookingDetails() {
  const { startLoading, stopLoading } = useLoading();
  const [bookingDetails, setBookingDetails] = useState(null);

  const [originalAmount, setOriginalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(250);
  const [balanceAmount, setBalanceAmount] = useState(0);

  const [bookingForm, setBookingForm] = useState({
    bookingName: "",
    whatsAppNumber: "",
    email: "",
    specialPersonName: "",
    noOfPeople: 0,
    occasion: 0,
    message: "",
  });
  const [errors, setErrors] = useState({
    bookingName: false,
    whatsAppNumber: false,
    email: false,
  });

  const [selectedCake, setSelectedCake] = useState(null);
  const [cakesData, setCakesData] = useState([]);
  const [selectedDecor, setSelectedDecor] = useState([]);
  const [specialDecorData, setSpecialDecorData] = useState([]);

  const planID = localStorage.getItem("storedPlanId");
  const slotID = localStorage.getItem("storedSlotId");
  const slotDate = localStorage.getItem("storedDate");

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Example: 10 digits
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "noOfPeople") {
      callCalculation(value, selectedCake, selectedDecor);
    }

    setBookingForm({ ...bookingForm, [name]: value });

    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleSelectChange = (value) => {
    setBookingForm((prevState) => ({
      ...prevState,
      occasion: value,
    }));
  };

  const setFirst = async (data) => {
    setBookingForm((prevState) => ({
      ...prevState,
      noOfPeople: data.people,
    }));
    setOriginalAmount(data.amount);
    setDiscountAmount(data.amount - data.maxdiscount);
    setSubTotal(data.amount - data.maxdiscount);
    setTotalPayable(data.amount - data.maxdiscount);
    setBalanceAmount(data.amount - data.maxdiscount - advanceAmount);
  };

  const callCalculation = async (
    noOfPeople,
    selectedCake = null,
    selectedDecor = []
  ) => {
    const numberOfPeople = parseInt(noOfPeople, 10);
    const extraPeople = numberOfPeople > 2 ? numberOfPeople - 2 : 0;
    const additionalCost = extraPeople * 150;

    const previousExtraPeople =
      parseInt(bookingForm.noOfPeople, 10) > 2
        ? parseInt(bookingForm.noOfPeople, 10) - 2
        : 0;
    const previousAdditionalCost = previousExtraPeople * 150;
    const difference = additionalCost - previousAdditionalCost;

    const cakeCost = selectedCake ? selectedCake.amount : 0;
    const decorationsCost = selectedDecor.reduce(
      (total, decoration) => total + decoration.amount,
      0
    );

    // Update original amount and discount amount based on number of people
    await setOriginalAmount(originalAmount + difference);
    await setDiscountAmount(discountAmount + difference);

    // Calculate the new subtotal by adding cake and decoration costs
    const newSubTotal =
      discountAmount + difference + cakeCost + decorationsCost;

    // Update state
    await setSubTotal(newSubTotal);
    await setTotalPayable(newSubTotal);
    setBalanceAmount(newSubTotal - advanceAmount);
  };

  // const calSubTotal = useMemo(() => {
  //   const initialSubtotal = selectedPlan ? selectedPlan.amount || 0 : 0;

  //   const extraMembersCost =
  //     bookingForm.noOfGuests > 2 ? (bookingForm.noOfGuests - 2) * 150 : 0;
  //   setExtraMembersAmount(extraMembersCost);

  //   const cakeCost = selectedCake ? selectedCake.amount || 0 : 0;

  //   const decorationsCost = selectedDecor.reduce(
  //     (total, decoration) => total + decoration.amount,
  //     0
  //   );

  //   return initialSubtotal + extraMembersCost + cakeCost + decorationsCost;
  // }, [bookingForm, selectedPlan, selectedCake, selectedDecor]);

  const getBookingDetails = () => {
    startLoading();
    axiosInstance
      .get(`customer/get-plan/${planID}/${slotID}`)
      .then((response) => {
        if (response.data.success === 1) {
          const data = response.data.data;
          setBookingDetails(data);
          setFirst(data);
          stopLoading();
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const bookNow = () => {
    const newErrors = {};
  
    // Validate bookingName (Name)
    if (bookingForm.bookingName.trim() === "") {
      newErrors.bookingName = true;
    }
  
    // Validate phone number (whatsAppNumber)
    if (!validatePhoneNumber(bookingForm.whatsAppNumber)) {
      newErrors.whatsAppNumber = true;
    }
  
    // Validate email
    if (!validateEmail(bookingForm.email)) {
      newErrors.email = true;
    }
  
    // If there are any validation errors, update the state
    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
    } else {
      // Proceed with booking logic if no validation errors
      startLoading();
  
      const prmData = {
        bookingname: bookingForm.bookingName,
        people: bookingForm.noOfPeople,
        phone: bookingForm.whatsAppNumber,
        email: bookingForm.email,
        occasionid: bookingForm.occasion,
        specialpersonname: bookingForm.specialPersonName,
        message: bookingForm.message,
        planid: parseInt(planID, 10),
        slotid: parseInt(slotID, 10),
        date: slotDate,
        totalpayable: totalPayable,
        advanceamount: advanceAmount,
        amountdue: balanceAmount,
        cakeid: selectedCake ? selectedCake.id : 0,
        nameonthecake: "Happy Birthday!",
        specialdecorselected: selectedDecor ? selectedDecor : "None",
        combosselected: "Combo A, Combo B",
      };
  
      axiosInstance
        .post("book/book-now", prmData)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            let data = response.data.data;
            window.open(data.phonepayurl, "_blank", "noopener,noreferrer");
            const intervalId = setInterval(async () => {
              try {
                const statusResponse = await axiosInstance.get(
                  `book/payment-status/${data.bookingreference}`
                );
                if (statusResponse.data.success === 1) {
                  clearInterval(intervalId);
                  stopLoading();
                  toast.success("Booking successful!");
                  console.log("Payment successful!");
                } else {
                  console.log("Polling payment status...");
                }
              } catch (error) {
                console.error("Error polling payment status:", error.message);
              }
            }, 5000);
            sendEmail(data.bookingreference);
          } else {
            toast.error("Slot not available!");
            stopLoading();
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
          toast.error(`Error: ${error.message}`);
          stopLoading();
        });
    }
  };

  const sendEmail = (referenceid) => {
    const prmData = {
      referenceid: referenceid,
    };
    axiosInstance
      .post('mail/send-mail-confirmation', prmData)
      .then((response) => {
        if (response.data.success === 1) {
          const data = response.data.data;
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <div className="px-5 py-5">
      <ToastContainer />
      <Helmet>
        <title>Booking Page</title>
        <meta
          name="description"
          content="This is the booking page of our website."
        />
        <meta property="og:title" content="Booking Page" />
        <meta
          property="og:description"
          content="This is the booking page of our website."
        />
      </Helmet>
      <div className="layout grid grid-cols-12 gap-5">
        <div className="col-span-12 lg:col-span-8 flex flex-col gap-8">
          <BookingForm
            bookingForm={bookingForm}
            errors={errors}
            bookingDetails={bookingDetails}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />
          <Cakes
            selectedCake={selectedCake}
            setSelectedCake={setSelectedCake}
            cakesData={cakesData}
            setCakesData={setCakesData}
            //
            bookingForm={bookingForm}
            selectedDecor={selectedDecor}
            callCalculation={callCalculation}
          />
          {/* <hr className="border-black m-5" /> */}
          <SpecialDecor
            selectedDecor={selectedDecor}
            setSelectedDecor={setSelectedDecor}
            specialDecorData={specialDecorData}
            setSpecialDecorData={setSpecialDecorData}
            //
            bookingForm={bookingForm}
            selectedCake={selectedCake}
            callCalculation={callCalculation}
          />
        </div>
        <div className="col-span-12 lg:col-span-4">
          <BookingSummary
            bookingDetails={bookingDetails}
            originalAmount={originalAmount}
            discountAmount={discountAmount}
            subTotal={subTotal}
            totalPayable={totalPayable}
            advanceAmount={advanceAmount}
            balanceAmount={balanceAmount}
            selectedDecor={selectedDecor}
            setSelectedDecor={setSelectedDecor}
            selectedCake={selectedCake}
            setSelectedCake={setSelectedCake}
            bookingForm={bookingForm}
            callCalculation={callCalculation}
            slotDate={slotDate}
            bookNow={bookNow}
          />
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;
