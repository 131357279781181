import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import MainPage from "./pages/OurSpace/index.js";
import SubPage from "./pages/OurSpace/_innerPage.js";
import BookingDetails from "./pages/BookingDetails.js";
import BookingResponse from "./pages/BookingResponse.js";

import { LoadingProvider } from "./config/LoadingContext.js";
import GlobalSpinner from "./components/GlobalSpinner.js";

import whatsapp from "./assets/whatsapp.svg";
import Payment from "./pages/Payment.js";

function App() {
  return (
    <LoadingProvider>
      <GlobalSpinner />
      <Router>
        <Header />
        <main className="mainBody">
          <div>
            <a
              href="https://wa.me/7204310888"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={whatsapp}
                alt=""
                className="w-[12%] md:w-[4%] fixed bottom-8 right-4 z-50"
              />
            </a>
          </div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/our-space" element={<MainPage />} />
            <Route path="/our-space/:roomName" element={<SubPage />} />
            <Route
              path="/booking-details/:roomName"
              element={<BookingDetails />}
            />
            <Route
              path="/booking-response/:referenceNumber"
              element={<BookingResponse />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="payment" element={<Payment />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </LoadingProvider>
  );
}

export default App;
