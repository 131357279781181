import React, { useState } from "react";
import { Helmet } from "react-helmet";
import phone from "../assets/phone.svg";
import mail from "../assets/mail.svg";
import clock from "../assets/clock.svg";
import axiosInstance from "../config/axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    email: false,
  });

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Example: 10 digits
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const newErrors = {};

  //   Object.keys(formData).forEach((field) => {
  //     const value = formData[field];

  //     // Check if the field is a string before calling trim
  //     if (typeof value === "string" && value.trim() === "") {
  //       newErrors[field] = true;
  //     } else if (value === "") {
  //       newErrors[field] = true;
  //     }
  //   });

  //   // Specific validation for phone number and email
  //   if (!validatePhoneNumber(formData.whatsAppNumber)) {
  //     newErrors.whatsAppNumber = true;
  //   }

  //   if (!validateEmail(formData.email)) {
  //     newErrors.email = true;
  //   }

  //   // Set errors if any field is empty
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       ...newErrors,
  //     }));
  //   } else {
  //     let prmData = {
  //       name: formData.name,
  //       phone: formData.phone,
  //       email: formData.email,
  //       message: formData.message,
  //     };
  //     axiosInstance
  //       .post("contact-us/save-lead", prmData)
  //       .then((response) => {
  //         let data = response.data;
  //         if (data.success == 200) {
  //           alert("success");
  //           setFormData({
  //             name: "",
  //             phone: "",
  //             email: "",
  //             message: "",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error.message);
  //       });
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    let prmData = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      message: formData.message,
    };
    axiosInstance
      .post("contact-us/save-lead", prmData)
      .then((response) => {
        let data = response.data;
        if (data.success == 200) {
          toast.success("successful!");
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
    
  };

  return (
    <div className="mb-[20px]">
      <ToastContainer />
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="This is the contact us page of our website."
        />
        <meta property="og:title" content="Contact Us Page" />
        <meta
          property="og:description"
          content="This is the contact us page of our website."
        />
      </Helmet>
      <div className="flex-row md:flex md:justify-evenly md:items-center p-[30px]">
        <div className="mb-[20px] md:mb-0">
          <h2 className="text-center md:text-left text-[20px] font-semibold mb-4">
            Contact Us
          </h2>
          <div className="flex flex-col gap-4">
            <a href="tel:7204310888">
              <div className="flex items-center gap-4">
                <div className="iconBackground flex justify-center items-center">
                  <div>
                    <img src={phone} alt="" />
                  </div>
                </div>
                <div className="">7204310888</div>
              </div>
            </a>
            <a
              href="mailto:Houseofdelightsofficial@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center gap-4">
                <div className="iconBackground flex justify-center items-center">
                  <div>
                    <img src={mail} alt="" />
                  </div>
                </div>
                <div className="">Houseofdelightsofficial@gmail.com</div>
              </div>
            </a>
            <div className="flex items-center gap-4">
              <div className="iconBackground flex justify-center items-center">
                <div>
                  <img src={clock} alt="" />
                </div>
              </div>
              <div className="">9:00 AM - 5:00 PM</div>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="w-full md:w-[40%] flex flex-col gap-4"
        >
          <div className="md:flex gap-2">
            {/* <Input
              label="Email"
              name="email"
              value={formData.email}
              error={errors.email}
              success={!errors.email && formData.email !== ""}
              onChange={handleChange}
              className="!h-[60px] !border-[#ffd700] !focus:border-[#ffd700] !focus:ring-[#ffd700]"
            /> */}
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              placeholder="Name"
              onChange={handleChange}
              required
              className="inputClass mb-[15px] md:mb-0 border-1 border-[#fff]"
            />
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              placeholder="Phone"
              onChange={handleChange}
              required
              className="inputClass"
            />
          </div>
          <div className="">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleChange}
              required
              className="inputClass"
            />
          </div>
          <div className="">
            <textarea
              id="message"
              name="message"
              rows={2}
              value={formData.message}
              placeholder="Message"
              onChange={handleChange}
              required
              className="textareaClass"
            />
          </div>
          <button type="submit" className="button">
            <span>Submit</span>
          </button>
        </form>
      </div>
      <div className="w-full md:w-[60%] m-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8423850222466!2d77.5925096!3d12.917849899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a78eb07241%3A0xed99d344fe1faac5!2sHouse%20of%20Delights!5e0!3m2!1sen!2sin!4v1727150623461!5m2!1sen!2sin"
          width="100%"
          height="450"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
