import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Set your base URL here
    timeout: 10000, // Set a timeout if needed
    headers: {
        'Content-Type': 'application/json',
        // Add any other custom headers if needed
    },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        // Do something before request is sent
        // For example, add an authorization token
        // config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Handle response error
        return Promise.reject(error);
    }
);

export default axiosInstance;
