import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../css/custom-css.css";
import { Carousel } from "@material-tailwind/react";

function SubPage() {
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const [innerPageData, setInnerPageData] = useState({});
  const [slotsData, setSlotsData] = useState([]);
  const [availableSlotsData, setAvailableSlotsData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [slotID, setSlotID] = useState(0);
  const [formattedDate, setFormattedDate] = useState(formatDate(new Date()));
  const [roomID, setRoomID] = useState(0);
  const { roomName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (roomName) {
      case "red_love":
        setRoomID(1);
        break;
      case "blush_n_bloom":
        setRoomID(2);
        break;
      case "golden_glam":
        setRoomID(3);
        break;
      case "blue_n_bliss":
        setRoomID(4);
        break;
      default:
        setRoomID(0);
    }
  }, [roomName]);

  useEffect(() => {
    if (roomID > 0) {
      getIndividualRoomData();
      getAllSlots();
      getAvailableSlots();
    }
  }, [roomID]);

  useEffect(() => {
    if (roomID > 0) {
      getAvailableSlots();
    }
  }, [formattedDate]);

  const getIndividualRoomData = () => {
    axiosInstance
      .get(`customer/get-individual-plan/${roomID}`)
      .then((response) => {
        if (response.data.success === 1) {
          setInnerPageData(response.data.data[0]);
        } else {
          console.error("Unexpected response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const getAllSlots = () => {
    axiosInstance
      .get(`common/view-slots/${roomID}`)
      .then((response) => {
        if (response.data.success === 1) {
          setSlotsData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const getAvailableSlots = () => {
    axiosInstance
      .post("common/view-available-slots", {
        date: formattedDate,
        planId: roomID,
      })
      .then((response) => {
        if (response.data.success === 200) {
          setAvailableSlotsData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const setData = (url) => {
    localStorage.setItem("storedPlanId", roomID);
    localStorage.setItem("storedDate", formattedDate);
    navigate(`/booking-details/${url}`);
  };

  const handleSlot = (id) => {
    localStorage.setItem("storedSlotId", id);
    setSlotID(id);
  };

  const handleDate = (newDate) => {
    setDate(newDate);
    setFormattedDate(formatDate(newDate));
  };

  const highlightSelected = (id, available) => {
    if (available) {
      if (slotID === id) {
        return "time_slot_btn active";
      } else {
        return "time_slot_btn";
      }
    } else {
      return "time_slot_btn disabled";
    }
  };

  function convertTo24Hour(time12h) {
    const [time, period] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function getCurrentTimeIn24HourFormat() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  function getSlotTimes(description) {
    const [startTime, endTime] = description.split(" - ");
    let startTimeFormat = convertTo24Hour(startTime);
    let endTimeFormat = convertTo24Hour(endTime);

    // Check if the slot ends after midnight (next day)
    let isNextDay =
      timeToMinutes(endTimeFormat) < timeToMinutes(startTimeFormat);
    return { startTime: startTimeFormat, endTime: endTimeFormat, isNextDay };
  }

  const updatedSlots = slotsData.map((slot) => {
    const { startTime, endTime, isNextDay } = getSlotTimes(slot.description);
    const currentTime = getCurrentTimeIn24HourFormat();
    // const currentTime = '24:40';

    const isToday = formatDate(date) === formatDate(new Date());

    // Determine if the slot is in the past for today
    const isPastToday =
      isToday && timeToMinutes(endTime) < timeToMinutes(currentTime);

    // Check if the slot is available in the API response
    const isAvailable = availableSlotsData.some(
      (availableSlot) => availableSlot.id === slot.id
    );

    // Available if it's not past today or if it's a slot for the next day
    return {
      ...slot,
      available: isAvailable && (!isPastToday || isNextDay),
    };
  });

  return (
    <div className="p-[15px] md:p-[30px]">
      <Helmet>
        <title>{innerPageData.title}</title>
      </Helmet>
      <div className="layout flex flex-col gap-10">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="col-span-12 lg:col-span-6">
            <div className="ourSpaceImage">
              <Carousel
                transition={{ duration: 2 }}
                autoplay={true}
                loop={true}
                className=""
              >
                {innerPageData.multiImage &&
                Array.isArray(JSON.parse(innerPageData.multiImage)) ? (
                  JSON.parse(innerPageData.multiImage).map((image, index) => (
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_IMAGE_URL}hor/${image}`}
                      alt={`Slide ${index + 1}`}
                      className="rounded-[24px] w-full h-[300px] lg:h-[500px] object-cover"
                    />
                  ))
                ) : (
                  <p>No images available</p>
                )}
              </Carousel>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 flex items-center">
            <div className="flex flex-col gap-5">
              <div className="roomHeading">
                <h4 className="text-[22px] sm:text-[24px] md:text-[24px] lg:text-[28px] xl:text-[30px] font-semibold">
                  {innerPageData.title}
                </h4>
              </div>
              <div className="flex items-center gap-1">
                <h4 className="text-[16px] text-[#f44336] line-through">
                  Rs.{innerPageData.amount}
                </h4>
                <h4 className="text-[20px]">
                  Rs.{innerPageData.amount - innerPageData.maxdiscount}
                </h4>
              </div>
              <div className="roomDescription">
                <div
                  dangerouslySetInnerHTML={{
                    __html: innerPageData.description,
                  }}
                  className="text-[16px] sm:text-[18px] text-left font-normal ml-5"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-[30px]">
          <div className="col-span-12 lg:col-span-6">
            <Calendar
              onChange={handleDate}
              value={date}
              minDate={new Date()}
              maxDate={new Date(2024, 11, 31)}
              showNavigation={true}
              showNeighboringMonth={false}
            />
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="flex flex-col justify-center  gap-4">
              <p className="font-bold">Selected Date: {formattedDate}</p>
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2 w-full">
                {updatedSlots.map((eachTime, index) => (
                  <button
                    key={index}
                    className={highlightSelected(
                      eachTime.id,
                      eachTime.available
                    )}
                    onClick={() => handleSlot(eachTime.id)}
                  >
                    {eachTime.description}
                  </button>
                ))}
              </div>
              <button
                className={slotID == 0 ? "bookNowBtn disabled" : "bookNowBtn"}
                onClick={() => setData(innerPageData.subid)}
                disabled={slotID === 0}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubPage;
