import React, { useState } from 'react';
import axios from 'axios';

const Payment = () => {
  const [amount, setAmount] = useState('100');
  const [transactionId, setTransactionId] = useState(`TXN_${Date.now()}`);
  const [customerPhone, setCustomerPhone] = useState('9980400458');
  const [customerEmail, setCustomerEmail] = useState('nprjwl@gmail.com');
  const [message, setMessage] = useState('');

  const handlePayment = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your Node.js backend
      const response = await axios.post('http://localhost:5050/api/initiate-payment', {
        amount: parseInt(amount) * 100, // Convert to paise
        transactionId,
        customerPhone,
        customerEmail,
      });

      // Handle the response from the server
      if (response.data.success) {
        // Redirect user to the payment gateway if needed, or handle according to the response
        setMessage('Payment initiated successfully! Please follow the instructions.');
      } else {
        setMessage('Failed to initiate payment. Please try again.');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setMessage('An error occurred while initiating payment.');
    }
  };

  return (
    <div className="payment-container">
      <h2>Initiate Payment</h2>
      <form onSubmit={handlePayment}>
        <div>
          <label>Amount:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="text"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Pay Now</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Payment;
