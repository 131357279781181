import React from "react";

function NotFound() {
  return (
    <div>
        error
    </div>
  );
}

export default NotFound;
