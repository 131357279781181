import React from "react";
import { NavLink } from "react-router-dom";
import instagram from "../assets/instagram.svg";

function Footer() {
  return (
    <div className="footer bg-[#ffd700] px-[20px] py-[50px]">
      <div className="layout">
        <div className="grid grid-cols-12 gap-y-[40px] sm:gap-[20px]">
          <div className="col-span-12 lg:col-span-3 text-center sm:text-left">
            <div className="happyTitle">
              <h2 className="font-bold pb-[10px]">
                <a href="/">House Of Delights</a>
              </h2>
            </div>
            <div className="followTitle flex items-center justify-center sm:justify-start">
              <h6 className="font-bold">Follow Us On</h6>
              <a
                href="https://www.instagram.com/house_of_delights.in?igsh=MWk4a3k4NTgyaXhpZQ%3D%3D&utm_source=qr"
                hrefLang="en"
                target="_blank"
              >
                <img src={instagram} className="w-[32px]" />
              </a>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-5 lg:col-span-5">
            <div className="QuickLinksTitle">
              <h6 className="font-bold">Quick Links</h6>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-6 lg:col-span-6">
                <div className="pageLinks">
                  <ul>
                    <li className="">
                      {/* <NavLink to="/about">About Us</NavLink> */}
                      <a href="/about">About Us</a>
                    </li>
                    <li className="">
                      {/* <NavLink to="/contact">Contact Us</NavLink> */}
                      <a href="/contact">Contact Us</a>
                    </li>
                    <li className="">
                      {/* <NavLink to="/our-space/gala_red">Our Space</NavLink> */}
                      <a href="/our-space/red_love">Our Space</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-span-6 lg:col-span-6">
                <div className="pageLinks">
                  <ul>
                    <li className="">
                      <a href="" hrefLang="">
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="">
                      <a href="" hrefLang="">
                        Refund Policy
                      </a>
                    </li>
                    <li className="">
                      <a href="" hrefLang="">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-7 lg:col-span-4">
            <div className="addressTitle">
              <h6 className="font-bold">Address</h6>
            </div>
            <div className="address">
              <h6 className="">
                House Of Delights
                <br />
                House of delights, 2nd floor, 1313, 25th Main Rd, near
                Pantaloons Mall, Banashankari 6th Stage 9th Block, Jayanagara
                9th Block, Jayanagar, Bengaluru, Karnataka 560069
              </h6>
            </div>
            <div className="phoneNumber p-[40px_0_0] sm:p-[20px_0_0]">
              <h6 className="font-normal">
                <span className="font-bold">Phone No:</span>
                <a href="tel:7204310888" target="_blank">
                  7204310888
                </a>
              </h6>
            </div>
            <div className="emailId">
              <h6 className="font-normal">
                <span className="font-bold">Mail Us:</span>
                <a
                  href="mailto:Houseofdelightsofficial@gmail.com"
                  target="_blank"
                >
                  Houseofdelightsofficial@gmail.com
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div className="copyRightTitle pt-[50px] sm:pt-[30px]">
          <h6 className="text-center">
            Copyright ©{new Date().getFullYear()} All Rights Reserved | House Of
            Delights
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Footer;
