import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import menuIcon from "../assets/menu-icon.svg";
import menuClose from "../assets/close.svg";
import { Drawer } from "@material-tailwind/react";

function Header() {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  return (
    <header className="fixed top-0 z-50 bg-[#fff] w-full h-[50px] md:h-[100px] p-[0px_30px] header_theme">
      {/* mobile */}
      <div className="md:hidden flex justify-between items-center h-full">
        <NavLink to="/">
          <div className="text-[20px] font-semibold italic">
            House Of Delights
          </div>
        </NavLink>
        <React.Fragment>
          <div onClick={openDrawer}>
            <img src={menuIcon} alt="" className="w-[24px] h-[24px]" />
          </div>
          <Drawer
            open={open}
            onClose={closeDrawer}
            className="!h-fit rounded-[15px] p-5"
          >
            <div className="flex justify-between items-center">
              <div className="text-[20px] font-semibold italic">
                House Of Delights
              </div>
              <img
                src={menuClose}
                alt=""
                className="w-[35px]"
                onClick={closeDrawer}
              />
            </div>
            <hr className="my-2 border-[#b2abab]" />
            <div className="flex flex-col">
              <NavLink
                to="/"
                onClick={closeDrawer}
                className="text-[#000] text-[18px] font-semibold"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                onClick={closeDrawer}
                className="text-[#000] text-[18px] font-semibold my-2"
              >
                About Us
              </NavLink>
              <NavLink
                to="/contact"
                onClick={closeDrawer}
                className="text-[#000] text-[18px] font-semibold"
              >
                Contact Us
              </NavLink>
            </div>
            <hr className="my-2 border-[#b2abab]" />
            <div className="flex flex-col gap-2">
              <NavLink to="/our-space/red_love" onClick={closeDrawer}>
                Red Love
              </NavLink>
              <NavLink to="/our-space/blush_n_bloom" onClick={closeDrawer}>
                Blush N Bloom
              </NavLink>
              <NavLink to="/our-space/golden_glam" onClick={closeDrawer}>
                Golden Glam
              </NavLink>
              <NavLink to="/our-space/blue_n_bliss" onClick={closeDrawer}>
                Blue N Bliss
              </NavLink>
            </div>
            <hr className="my-2 border-[#b2abab]" />
            <NavLink to="/our-space" onClick={closeDrawer}>
              <button type="submit" className="button">
                <span>Book Now</span>
              </button>
            </NavLink>
          </Drawer>
        </React.Fragment>
      </div>
      {/* desktop */}
      <div className="w-full h-full bg-[#fff] flex justify-between items-center hidden md:flex">
        <div
          className="left border-1 border-[#ffd700]"
          style={{ border: "1px solid #FFD700" }}
        >
          <NavLink to="/">
            <div className="text-[20px] font-semibold italic">
              House Of Delights
            </div>
          </NavLink>
        </div>
        <div className="center w-[60%] flex justify-evenly">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-[#FFD700] text-[18px] font-semibold"
                : "text-[#000] text-[18px] font-semibold hover:text-[#FFD700]"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-[#FFD700] text-[18px] font-semibold"
                : "text-[#000] text-[18px] font-semibold hover:text-[#FFD700]"
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive
                ? "text-[#FFD700] text-[18px] font-semibold"
                : "text-[#000] text-[18px] font-semibold hover:text-[#FFD700]"
            }
          >
            Contact Us
          </NavLink>
          <div className="relative">
            <button
              className="text-[#000] text-[18px] font-semibold hover:text-[#FFD700]"
              onMouseEnter={() => setOpenMenu(true)}
              onMouseLeave={() => setOpenMenu(false)}
            >
              <NavLink
                to="/our-space/red_love"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#FFD700] text-[18px] font-semibold pointer-events-none"
                    : "text-[#000] text-[18px] font-semibold hover:text-[#FFD700] pointer-events-non"
                }
              >
                Our Space
              </NavLink>
            </button>

            {openMenu && (
              <ul
                className="absolute w-[250px] left-0 bg-[#fff] rounded-lg shadow-lg p-[15px] flex flex-col gap-2"
                onMouseEnter={() => setOpenMenu(true)}
                onMouseLeave={() => setOpenMenu(false)}
              >
                <NavLink
                  to="/our-space/red_love"
                  onClick={() => setOpenMenu(false)}
                >
                  <li className="hover:text-[#FFD700]">Red Love</li>
                </NavLink>
                <NavLink
                  to="/our-space/blush_n_bloom"
                  onClick={() => setOpenMenu(false)}
                >
                  <li className="hover:text-[#FFD700]">Blush N Bloom</li>
                </NavLink>
                <NavLink
                  to="/our-space/golden_glam"
                  onClick={() => setOpenMenu(false)}
                >
                  <li className="hover:text-[#FFD700]">Golden Glam</li>
                </NavLink>
                <NavLink
                  to="/our-space/blue_n_bliss"
                  onClick={() => setOpenMenu(false)}
                >
                  <li className="hover:text-[#FFD700]">Blue N Bliss</li>
                </NavLink>
              </ul>
            )}
          </div>
        </div>
        <div className="right">
          <NavLink to="/our-space">
            <button type="submit" className="button">
              <span>Book Now</span>
            </button>
          </NavLink>
        </div>
      </div>
    </header>
  );
}

export default Header;
