import React from "react";
import { Input, Button } from "@material-tailwind/react";
import removeIcon from "../assets/delete.svg";

function BookingSummary({
  bookingDetails,
  originalAmount,
  discountAmount,
  subTotal,
  totalPayable,
  advanceAmount,
  balanceAmount,
  selectedDecor,
  setSelectedDecor,
  selectedCake,
  setSelectedCake,
  bookingForm,
  callCalculation,
  slotDate,
  bookNow
}) {
  const removeDecor = (decorId) => {
    const updatedDecor = selectedDecor.filter((decor) => decor.id !== decorId);
    setSelectedDecor(updatedDecor);
    callCalculation(bookingForm.noOfPeople, selectedCake, updatedDecor);
  };

  const removeCake = () => {
    setSelectedCake(null);
    callCalculation(bookingForm.noOfPeople, null, selectedDecor);
  };

  return (
    <div className="booking_summary flex flex-col gap-4">
      <div className="text-[#1a1a1a] text-[25px] leading-[28px] tracking-[3px]">
        Booking Summary
      </div>
      {bookingDetails && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="text-[18px]">
              {bookingDetails.title}
              <small>({bookingForm.noOfPeople} People)</small>
            </div>
            <div className="flex gap-1">
              <span className="line-through text-[#f44336]">
                Rs.{originalAmount}
              </span>
              Rs.{discountAmount}
            </div>
          </div>
          <div>
            <small>Date: {slotDate} </small>
          </div>
          <div>
            <small>Slot: {bookingDetails.slotdescription}</small>
          </div>
        </div>
      )}
      <hr className="my-2 border border-black" />
      <div>
        {selectedCake && (
          <div className="flex justify-between items-center">
            <div className="flex gap-1">
              <span className="">{selectedCake.description}</span>
              <img
                src={removeIcon}
                alt=""
                onClick={removeCake}
                className="cursor-pointer"
              />
            </div>
            <span>Rs.{selectedCake.amount}</span>
          </div>
        )}
        {selectedDecor.length > 0 && (
          <ul>
            {selectedDecor.map((eachData) => {
              return (
                <li
                  key={eachData.id}
                  className="flex justify-between items-center"
                >
                  <div className="flex gap-1">
                    <span className="">{eachData.name}</span>
                    <img
                      src={removeIcon}
                      alt=""
                      onClick={() => removeDecor(eachData.id)}
                      className="cursor-pointer"
                    />
                  </div>
                  <span>Rs.{eachData.amount}</span>
                </li>
              );
            })}
          </ul>
        )}
        <div className="flex justify-between">
          <div className="text-[16px] font-bold">Sub Total</div>
          <h3>Rs.{subTotal}</h3>
        </div>
        {/* <h3>Have a coupon code!</h3>
        <div className="flex justify-between">
          <Input label="Coupon Code" />​<Button color="blue">Apply</Button>
        </div> */}
      </div>
      <hr className="my-2 border border-black" />
      <div>
        <div className="flex justify-between text-[16px]">
          <b>Total Payable</b>
          <h3>Rs.{totalPayable}</h3>
        </div>
        <div className="flex justify-between">
          <b>Advance Amount</b>
          <h3>Rs.{advanceAmount}</h3>
        </div>
        <div className="flex justify-between">
          <b>Balance</b>
          <h3>Rs.{balanceAmount}</h3>
        </div>
      </div>
      <Button color="blue" className="w-full" onClick={bookNow}>
        PROCEED TO PAY
      </Button>
    </div>
  );
}

export default BookingSummary;
