import React, { useEffect } from "react";
import axiosInstance from "../config/axiosInstance";

function SpecialDecor({
  selectedDecor,
  setSelectedDecor,
  specialDecorData,
  setSpecialDecorData,
  bookingForm,
  selectedCake,
  callCalculation,
}) {
  const toggleCardSelection = (card) => {
    const isSelected = selectedDecor.some(
      (selectedCard) => selectedCard.id === card.id
    );

    if (isSelected) {
      const updatedDecor = selectedDecor.filter(
        (selectedCard) => selectedCard.id !== card.id
      );
      setSelectedDecor(updatedDecor);
      callCalculation(bookingForm.noOfPeople, selectedCake, updatedDecor); // Recalculate on deselect
    } else {
      const updatedDecor = [...selectedDecor, card];
      setSelectedDecor(updatedDecor);
      callCalculation(bookingForm.noOfPeople, selectedCake, updatedDecor); // Recalculate on select
    }
  };

  const highlightSelected = (id) => {
    const isSelected = selectedDecor.some((card) => card.id === id);
    return isSelected ? "decorCard gap-2 active" : "decorCard gap-2";
  };

  useEffect(() => {
    axiosInstance
      .get("data/get-special-decor-dd")
      .then((response) => {
        if (response.data.success === 1) {
          setSpecialDecorData(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, [setSpecialDecorData]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {specialDecorData.length > 0 &&
        specialDecorData.map((card) => (
          <div
            key={card.id}
            className={highlightSelected(card.id)}
            onClick={() => toggleCardSelection(card)}
          >
            <img
              src={card.image}
              alt=""
              className="w-[100px] h-[100px] rounded"
            />
            <div>
              <div className="text-[14px] font-medium line height-[20px]">
                {card.name}
              </div>
              <div className="text-[11px] font-normal line height-[16px]">
                {card.description}
              </div>
              <div className="text-[14px] font-medium line height-[20px]">
                Rs.{card.amount}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default SpecialDecor;
