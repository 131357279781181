import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

// components
// css
import { Carousel } from "@material-tailwind/react";
//
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import money from "../assets/money.png";
import time from "../assets/time.png";
import location from "../assets/location.png";
import celebrate from "../assets/celebrate.png";
import Gallery from "../components/Gallery";

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Home Page</title>
        <meta
          name="description"
          content="This is the home page of our website."
        />
        <meta property="og:title" content="Home Page" />
        <meta
          property="og:description"
          content="This is the home page of our website."
        />
      </Helmet>

      <Carousel
        transition={{ duration: 2 }}
        autoplay={true}
        loop={true}
        className=""
        style={{ height: "calc(100vh - 100px)" }}
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}banner_4.jpg`}
          alt=""
          className="h-full w-full object-cover"
        />
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}banner_2.jpeg`}
          alt=""
          className="h-full w-full object-cover"
        />
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}banner_3.jpeg`}
          alt=""
          className="h-full w-full object-cover"
        />
      </Carousel>

      <div className="flex flex-col gap-20">
        <div className="">
          <div className="text-center text-[24px] font-medium py-5 ml-[20px]">
            OUR SPACE
          </div>
          <Slider {...settings} className="w-[90%] m-auto ">
            <div className="rounded-[15px] px-5">
              <NavLink to="/our-space/red_love">
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}ver/red_love.jpg`}
                    alt=""
                    className="rounded-t-[15px] w-full h-[400px]"
                  />
                </div>
                <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                  Red Love
                </div>
              </NavLink>
            </div>
            <div className="rounded-[15px] px-5">
              <NavLink to="/our-space/blush_n_bloom">
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}ver/blush_bloom.jpg`}
                    alt=""
                    className="rounded-t-[15px] w-full h-[400px]"
                  />
                </div>
                <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                  Blush N Bloom
                </div>
              </NavLink>
            </div>
            <div className="rounded-[15px] px-5">
              <NavLink to="/our-space/golden_glam">
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}ver/golden_glam.jpg`}
                    alt=""
                    className="rounded-t-[15px] w-full h-[400px]"
                  />
                </div>
                <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                  Golden Glam
                </div>
              </NavLink>
            </div>
            <div className="rounded-[15px] px-5">
              <NavLink to="/our-space/blue_n_bliss">
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}ver/blue_bliss.jpg`}
                    alt=""
                    className="rounded-t-[15px] w-full h-[400px]"
                  />
                </div>

                <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                  Blue N Bliss
                </div>
              </NavLink>
            </div>
          </Slider>
        </div>
        <div>
          <div className="text-center text-[24px] font-medium py-5 ml-[20px]">
            PROCESS
          </div>
          <div className="bg-black flex flex-col gap-10 sm:flex-row sm:gap-0 sm:w-[90%] sm:m-auto justify-between p-5">
            <div className="flex items-center gap-3">
              <div className="stepsCard">
                <img src={time} alt="" />
              </div>
              <div>
                <span className="text-[#ffd700]">
                  <b>Time Slot</b>
                </span>
                <h3 className="text-[#ffd700]">
                  Book your time slot <br /> & Decoration
                </h3>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="stepsCard">
                <img src={money} alt="" />
              </div>
              <div>
                <span className="text-[#ffd700]">
                  <b>Payment</b>
                </span>
                <span>
                  <br />
                </span>
                <h3 className="text-[#ffd700]">
                  Make Advance <br /> Payment First
                </h3>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="stepsCard">
                <img src={celebrate} alt="" />
              </div>
              <div>
                <span className="text-[#ffd700]">
                  <b>Enjoy</b>
                </span>
                <h3 className="text-[#ffd700]">
                  Celebrate with <br /> your loved ones
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="text-center text-[24px] font-medium py-5 ml-[20px]">
            OUR SERVICES
          </div>
          <Slider {...settings} className="w-[90%] m-auto ">
            <div className="rounded-[15px] px-5">
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}service/beverage.png`}
                  // src="https://thehappyscreens.in/assets/images/service/beverage.png"
                  alt=""
                  className="rounded-t-[15px] w-full"
                />
              </div>
              <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                Food
              </div>
            </div>
            <div className="rounded-[15px] px-5">
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}service/gifts.png`}
                  // src="https://thehappyscreens.in/assets/images/service/gifts.png"
                  alt=""
                  className="rounded-t-[15px] w-full"
                />
              </div>
              <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                Gifts
              </div>
            </div>
            <div className="rounded-[15px] px-5">
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}service/cakes.png`}
                  // src="https://thehappyscreens.in/assets/images/service/cakes.png"
                  alt=""
                  className="rounded-t-[15px] w-full"
                />
              </div>
              <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                Cake
              </div>
            </div>
            <div className="rounded-[15px] px-5">
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}service/flowers.png`}
                  // src="https://thehappyscreens.in/assets/images/service/flowers.png"
                  alt=""
                  className="rounded-t-[15px] w-full"
                />
              </div>
              <div className="w-full h-20 text-[22px] font-medium bg-[#fff] border-[#ffd700] border-2 flex items-center justify-center rounded-b-[15px]">
                Flowers
              </div>
            </div>
          </Slider>
        </div>
        <div>
          <div className="text-center text-[24px] font-medium py-5 ml-[20px]">
            GALLERY
          </div>
          <div>
            <Gallery />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
